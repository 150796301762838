import {
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  useMediaQuery,
  makeStyles,
  Dialog,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CustomPopOver from "./CustomPopOver";
import { checkIfLanguageIs } from "../utils/helper_functions";
import { ReactSession } from "react-client-session";
import ReactGA from "react-ga4";

const useStyles = makeStyles((_) => ({
  modalBody: {
    "& button:not(:last-child)": {
      marginBottom: "20px",
    },
  },
}));

const DownloadButton = ({ fileName }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? "simple-popover" : undefined;

  const handlePopoverButtonClick = (event) => {
    ReactGA.event({
      category: "User Actions",
      action: "Download Button Clicked",
      label: "Download",
      nonInteraction: false,
    });

    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    ReactGA.event({
      category: "User Actions",
      action: "Download Button Clicked",
      label: "Download",
      nonInteraction: false,
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const addScript = (fileName) => {
    const getCurrentURL = () => {
      const u = new URL(window.location.href);
      u.hash = "download";
      return u.toString();
    };

    const aid = localStorage.getItem("aid") ?? "1598265";
    const aname = localStorage.getItem("aname") ?? "";
    const script = document.createElement("script");
    if (fileName) {
      script.type = "text/javascript";
      script.innerHTML = `var _ll = _ll || [];
    _ll.push(["trackRequest", {
      "aid": '${aid}',
      "aname": '${aname}',
      "url": '${getCurrentURL()}',
      "sid": '${ReactSession.get("sid")}',
      "at": "controlled",
      "am": "regular",
      "im": {
        "title": '${fileName}',
        "dtype": "Database_Full_Item",
        "id": 'TLL_${fileName}',
      }
  }]);
  `;
    }
    document.body.appendChild(script);
  };
  const downloadPdf = (showLineNumber) => {
    if (showLineNumber) {
      ReactGA.event({
        category: "User Actions",
        action: "Download Lemma with lines Clicked",
        label: "Lemma with lines",
        nonInteraction: false,
      });
    } else {
      ReactGA.event({
        category: "User Actions",
        action: "Download Lemma Clicked",
        label: "Lemma",
        nonInteraction: false,
      });
    }

    window.open(
      `${
        process.env.REACT_APP_SERVER_HOST_NAME
      }/cite/download/lemma/${fileName}.xml?showLineNumber=${showLineNumber}&languageCode=${
        checkIfLanguageIs("de") ? "de" : "en"
      }`,
      "_self"
    );
    addScript(fileName);
  };

  const downloadCitations = () => {
    ReactGA.event({
      category: "User Actions",
      action: "Download Citations Clicked",
      label: "Citations",
      nonInteraction: false,
    });

    window.open(
      `${
        process.env.REACT_APP_SERVER_HOST_NAME
      }/cite/download/citations/${fileName}.xml?languageCode=${
        checkIfLanguageIs("de") ? "de" : "en"
      }`,
      "_self"
    );
    addScript(fileName);
  };

  const downloadModal = (
    <Dialog
      scroll="body"
      open={open}
      className="cite-container"
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="cite-modal">
        <div className="modal-header">
          <Typography
            align="center"
            variant="h2"
            className="gm"
            color="inherit"
          >
            {t("Download")}
          </Typography>
        </div>
        <div className="modal-close-btn">
          <IconButton onClick={handleClose}>
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L22.2132 22.2132" stroke="white" strokeWidth="2" />
              <path d="M22 1L0.786795 22.2132" stroke="white" strokeWidth="2" />
            </svg>
          </IconButton>
        </div>
        <div className={`modal-body ${classes.modalBody}`}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              downloadPdf(false);
              handleClose();
            }}
          >
            Lemma
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              downloadPdf(true);
              handleClose();
            }}
          >
            <Trans i18nKey="Lemma with lines">Lemma with lines</Trans>
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              downloadCitations();
              handleClose();
            }}
          >
            <Trans i18nKey="Citation">Citations</Trans>
          </Button>
        </div>
      </div>
    </Dialog>
  );

  const downloadIcon = (
    <svg
      width="10"
      height="15"
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.9389L5.00011 9.89844M5.00011 9.89844L1.00023 5.9389M5.00011 9.89844L5.00012 -0.000412597"
        stroke="#005D78"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M1 14H8.99977"
        stroke="#005D78"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
  return (
    <Fragment>
      {isDesktop ? (
        <>
          <Button
            square
            variant="outlined"
            className="newButton"
            startIcon={downloadIcon}
            aria-describedby={popoverId}
            onClick={handlePopoverButtonClick}
          >
            {t("Download")}
          </Button>
          <CustomPopOver
            id={popoverId}
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
          >
            <List>
              <ListItem
                button
                onClick={() => {
                  downloadPdf(false);
                  handlePopoverClose();
                }}
              >
                <Typography variant="h6" className="popup-option">
                  Lemma
                </Typography>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  downloadPdf(true);
                  handlePopoverClose();
                }}
              >
                <Typography variant="h6" className="popup-option">
                  <Trans i18nKey="Lemma with lines">Lemma with lines</Trans>
                </Typography>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  downloadCitations();
                  handlePopoverClose();
                }}
              >
                <Typography variant="h6" className="popup-option">
                  <Trans i18nKey="Citation">Citations</Trans>
                </Typography>
              </ListItem>
            </List>
          </CustomPopOver>
        </>
      ) : (
        <Button
          className="iconButton"
          onClick={handleOpen}
          disableFocusRipple
          variant="outlined"
          color="primary"
        >
          {downloadIcon}
        </Button>
      )}
      {downloadModal}
    </Fragment>
  );
};

export default DownloadButton;

DownloadButton.propTypes = {
  fileName: PropTypes.string,
};
